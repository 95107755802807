import { Icons } from "@/components/icons";

const dev = process.env.NODE_ENV === "development";
const forceProd = true;

const getEnv = (devValue: string, prodValue: string) => {
  if (forceProd) return prodValue;
  return dev ? devValue : prodValue;
};

const ids = {
  individualAnnual: {
    productId: {
      dev: "prod_QqtawNfET95i4J",
      prod: "prod_O4REWvflDgCtXp",
    },
    priceId: {
      dev: "price_1PzUa0QPAIC9au8AmgcjIggm",
      prod: "price_1R7S5iKqaLcdpMYsjiU3rstF",
    },
    coupon: {
      dev: "YCWUXXDP",
      prod: "pIcopLu1",
    },
  },
  individual: {
    productId: {
      dev: "prod_QqtawNfET95i4J",
      prod: "prod_O4REWvflDgCtXp",
    },
    priceId: {
      dev: "price_1PzO45QPAIC9au8AspnqVMJn",
      prod: "price_1R6h64KqaLcdpMYs7gawRMyq",
    },
    coupon: {
      dev: "YCWUXXDP",
      prod: "pIcopLu1",
    },
  },
  team: {
    productId: {
      dev: "prod_Qr5UOxAHh8lOuA",
      prod: "prod_P1REpaQKMj8lg7",
    },
    priceId: {
      dev: "price_1PzUpyQPAIC9au8AYsCnbFgJ",
      prod: "price_1PJqT1KqaLcdpMYswMBRTLxk",
    },
    coupon: {
      dev: "5f3sGlWs",
      prod: "2HBGsD7K",
    },
  },
  mobile: {
    priceId: {
      dev: "",
      prod: "price_1QBG4zKqaLcdpMYstApXUMbe",
    },
    coupon: {
      dev: "",
      prod: "",
    },
  },
  saas: {
    priceId: {
      dev: "price_1PzNIgQPAIC9au8ALD7kZpHi",
      prod: "price_1Q3PjDKqaLcdpMYsCRUnlZ4Q",
    },
    coupon: {
      dev: "",
      prod: "",
    },
  },
  startup: {
    priceId: {
      dev: "price_1PzNIzQPAIC9au8AfOmG7Isq",
      prod: "price_1Q3PkDKqaLcdpMYseG8ay0dW",
    },
    coupon: {
      dev: "",
      prod: "",
    },
  },
  agent: {
    priceId: {
      dev: "",
      prod: "price_1R6gisKqaLcdpMYsgAKw7ATA",
    },
    coupon: {
      dev: "",
      prod: "",
    },
  },
  devtool: {
    priceId: {
      dev: "",
      prod: "price_1QMXZHKqaLcdpMYsyQQ7xx43",
    },
    coupon: {
      dev: "",
      prod: "",
    },
  },
};

export const licenses = {
  individualAnnual: {
    name: "Individual License - Annual Access",
    productId: getEnv(
      ids.individualAnnual.productId.dev,
      ids.individualAnnual.productId.prod,
    ),
    priceId: getEnv(
      ids.individualAnnual.priceId.dev,
      ids.individualAnnual.priceId.prod,
    ),
    coupon: getEnv(
      ids.individualAnnual.coupon.dev,
      ids.individualAnnual.coupon.prod,
    ),
    period: "billed annually",
    description:
      "Beautifully crafted React + Tailwind CSS + Framer Motion components and templates for your next web project.",
    mode: "subscription",
    cta: "Get Annual Access",
    icon: Icons.logo,
  },
  individual: {
    name: "Individual License - Lifetime Access",
    productId: getEnv(
      ids.individual.productId.dev,
      ids.individual.productId.prod,
    ),
    period: "one-time payment",
    mode: "payment",
    priceId: getEnv(ids.individual.priceId.dev, ids.individual.priceId.prod),
    coupon: getEnv(ids.individual.coupon.dev, ids.individual.coupon.prod),
    description:
      "Beautifully crafted React + Tailwind CSS + Framer Motion components and templates for your next web project.",
    cta: "Get Access",
    icon: Icons.logo,
  },
  team: {
    name: "Team License",
    productId: getEnv(ids.team.productId.dev, ids.team.productId.prod),
    priceId: getEnv(ids.team.priceId.dev, ids.team.priceId.prod),
    coupon: getEnv(ids.team.coupon.dev, ids.team.coupon.prod),
    mode: "payment",
    description:
      "Beautifully crafted React + Tailwind CSS + Framer Motion components and templates for your team's next web project.",
    cta: "Get Access",
    icon: Icons.logo,
  },
  agent: {
    name: "AI Agent Template",
    priceId: getEnv(ids.agent.priceId.dev, ids.agent.priceId.prod),
    coupon: getEnv(ids.agent.coupon.dev, ids.agent.coupon.prod),
    description:
      "A professional landing page template designed for AI agent startups. Showcase your AI agent's features, pricing, and benefits to your customers.",
    video: "/agent-demo.mp4",
    cta: "Get Access",
    href: "/docs/templates/agent",
    preview: "https://agent-magicui.vercel.app/",
    icon: Icons.logo,
    mode: "payment",
    repo: "agent-template",
    owner: "magicuidesign",
  },
  devtool: {
    name: "Dev Tool Template",
    priceId: getEnv(ids.devtool.priceId.dev, ids.devtool.priceId.prod),
    coupon: getEnv(ids.devtool.coupon.dev, ids.devtool.coupon.prod),
    description:
      "A professional landing page template designed for developer tools and APIs. Showcase code snippets, highlight features, and display pricing plans. Includes an integrated MDX blog, fully responsive design, and easy customization options.",
    video: "/devtool-demo.mp4",
    cta: "Get Access",
    href: "/docs/templates/devtool",
    preview: "https://devtool-magicui.vercel.app/",
    icon: Icons.logo,
    mode: "payment",
    repo: "devtool-template",
    owner: "magicuidesign",
  },
  mobile: {
    name: "Mobile Template",
    priceId: getEnv(ids.mobile.priceId.dev, ids.mobile.priceId.prod),
    coupon: ids.mobile.coupon,
    description:
      "Showcase your mobile app with a professionally designed landing page. Features interactive app previews, smooth animations, and mobile-first design. Includes customizable sections for features, benefits, pricing, and app store integration.",
    video: "/mobile-demo.mp4",
    cta: "Get Access",
    href: "/docs/templates/mobile",
    preview: "https://mobile-magicui.vercel.app/",
    icon: Icons.logo,
    mode: "payment",
    repo: "mobile-template",
    owner: "magicuidesign",
  },
  saas: {
    name: "SaaS Template",
    priceId: getEnv(ids.saas.priceId.dev, ids.saas.priceId.prod),
    coupon: ids.saas.coupon,
    description:
      "Create a stunning landing page for your startup or product with our professionally designed template. Includes customizable sections, responsive design, and optimized for conversions.",
    video: "/saas-demo.mp4",
    cta: "Get Access",
    href: "/docs/templates/saas",
    preview: "https://saas-magicui.vercel.app/",
    icon: Icons.logo,
    mode: "payment",
    repo: "saas-template",
    owner: "magicuidesign",
  },
  startup: {
    name: "Startup Template",
    priceId: getEnv(ids.startup.priceId.dev, ids.startup.priceId.prod),
    coupon: ids.startup.coupon,
    description:
      "Launch your startup with a professional, responsive landing page. Includes customizable sections for hero, features, pricing, perfect for showcasing your product or idea.",
    icon: Icons.logo,
    video: "/startup-demo.mp4",
    cta: "Get Access",
    href: "/docs/templates/startup",
    preview: "https://startup-template-sage.vercel.app/",
    mode: "payment",
    repo: "startup-template",
    owner: "dillionverma",
  },
  portfolio: {
    priceId: undefined,
    name: "Portfolio Template",
    description:
      "Showcase your work with a sleek, modern portfolio page. This template features sections for projects, skills, and contact information, ideal for creatives and professionals looking to make a strong online impression.",
    icon: Icons.logo,
    video: "/portfolio-demo.mp4",
    cta: "Free Download",
    coupon: "YCWUXXDP",
    href: "/docs/templates/portfolio",
    preview: "https://portfolio-magicui.vercel.app/",
    repo: "portfolio",
    owner: "dillionverma",
  },
};

export const ALL_ACCESS_PRICE_IDS = [
  licenses.individual.priceId,
  licenses.team.priceId,
];

export const ALL_ACCESS_PRODUCT_IDS = [
  licenses.individual.productId,
  licenses.team.productId,
];
