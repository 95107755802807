"use client";

import {
  createContext,
  use,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { checkMultipleProductAccess } from "@/actions/user";
import { useUser } from "@/context/user-context";
import { useServerAction } from "zsa-react";

import { features } from "@/components/landing/features";

type AccessState = Record<
  string,
  {
    hasAccess: boolean;
    priceId: string;
    productId: string;
    amount: number | null;
    currency: string | null;
    name: string | null;
  }
>;
interface AccessContextType {
  accessState: AccessState;
  isLoading: boolean;
  refreshAccess: (priceIds?: string[]) => Promise<void>;
}

const AccessContext = createContext<AccessContextType | null>(null);

const defaultAccessState = (priceIds: string[]) =>
  Object.fromEntries(
    priceIds.map((id) => [
      id,
      {
        hasAccess: false,
        priceId: id,
        productId: "",
        amount: null,
        currency: null,
        name: null,
      },
    ]),
  );

export function AccessProvider({ children }: { children: React.ReactNode }) {
  const { userPromise } = useUser();
  const user = use(userPromise);
  const [accessState, setAccessState] = useState<AccessState>({});
  const [isLoading, setIsLoading] = useState(false);
  const { execute: checkAccess } = useServerAction(checkMultipleProductAccess);

  const refreshAccess = useCallback(
    async (priceIds?: string[]) => {
      if (!priceIds?.length) return;

      setIsLoading(true);

      try {
        const [data, err] = await checkAccess({ priceIds });
        if (err) {
          setAccessState(defaultAccessState(priceIds));
        } else if (data && typeof data === "object") {
          setAccessState((prev) => ({ ...prev, ...data }));
        } else {
          setAccessState(defaultAccessState(priceIds));
        }
      } catch (error) {
        console.error("Failed to check access:", error);
        setAccessState(defaultAccessState(priceIds));
      } finally {
        setIsLoading(false);
      }
    },
    [checkAccess, user],
  );

  useEffect(() => {
    const priceIds = features
      .filter((f) => !f.free && f.priceId)
      .map((f) => f.priceId!)
      .filter(Boolean);

    if (priceIds.length > 0) {
      refreshAccess(priceIds).catch((error) => {
        console.error("Failed to refresh access:", error);
      });
    }
  }, [refreshAccess]);

  return (
    <AccessContext.Provider
      value={{
        accessState,
        isLoading,
        refreshAccess,
      }}
    >
      {children}
    </AccessContext.Provider>
  );
}

export function useAccess() {
  const context = useContext(AccessContext);
  if (!context) {
    throw new Error("useAccess must be used within an AccessProvider");
  }
  return context;
}
